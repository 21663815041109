@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@500;700&display=swap');

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
}

.flexy {
  display: flex !important;
  flex: 1 auto;
  height: 100%;
  width: 100%;
}

model-viewer {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

model-viewer#reveal {
  --progress-bar-color: #000;
}

*:focus {
  outline: none !important;
}

*:-webkit-direct-focus {
  outline: none !important;
}
